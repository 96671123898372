require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/datepicker');

jQuery.fn.preventDoubleSubmission = function() {
  $(this).on('submit',function(e){
    var $form = $(this);

    if ($form.data('submitted') === true) {
      e.preventDefault();
    } else {
      $form.data('submitted', true);
    }
  });

  return this;
};

class Common {
  static initClass() {
    this.prototype.$container = null;
  }

  constructor($container) {
    this.$container = $container;

    this.initBasic();
    this.initAjaxPrefilter();
  }

  initBasic() {
    const self = this;

    const fixHelper = function(e, ui) {
      ui.children().each(()=> {
        $(this).width($(this).width());
      });
      return ui;
    };

    $('.js-sortable').each(function() {
      const $element = $(this)

      $element.find('.js-sortable-body').sortable({
        scrollSpeed: 1,
        helper: fixHelper,
        stop: function() {
          $.ajax({
            url: $element.data('url'),
            type: 'PUT',
            dataType: 'json',
            data: $(this).sortable('serialize')
          })
        }
      })
    })


    $(".js-datepicker").datepicker({
      dateFormat: "yy-mm-dd"
    });


    $('form').preventDoubleSubmission();

    this.$container.delegate('.js-post-item', 'click', function(e) {
      e.preventDefault();

      self.postItem($(this))
    });

    this.$container.delegate('.js-put-item', 'click', function(e) {
      e.preventDefault();

      self.putItem($(this))
    });

    this.$container.delegate('.js-delete-item', 'click', function(e) {
      e.preventDefault();


      if (confirm(`Are you sure?`)) {
        self.deleteItem($(this))
      }
    });

    $('input[type="file"]').change(function(e){
      $('.custom-file-label').html(e.target.files[0].name);
    });
  }

  deleteItem($element) {
    $.ajax({
      type: 'DELETE',
      url: $element.data('url')
    }).done(function(response) {
      window.location = (response && response.redirect) || '/'
    })
  }

  postItem($element) {
    $.ajax({
      type: 'POST',
      url: $element.data('url')
    }).done(function(response) {
      window.location = (response && response.redirect) || '/'
    })
  }

  putItem($element) {
    $.ajax({
      type: 'PUT',
      url: $element.data('url')
    }).done(function(response) {
      if (response && response.redirect === window.location.href) {
        window.location.reload()
        return
      }

      window.location.href = (response && response.redirect) || '/'

    })
  }

  initAjaxPrefilter() {
    $.ajaxPrefilter((options, originalOptions, xhr) => {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    })
  }
}

Common.initClass();
const root = window;

if (!root.App.NameSpaces.Common) {
  root.App.NameSpaces.Common = Common;
}

$(document).ready(() => {
  App.Instances.common = new Common($('body'));
});
